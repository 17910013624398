.cb-wrapper {
  position: relative;
}
.search-box {
  width: 100%;
}
.form-control.cb-header {
  min-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #e2e8f0; */
  padding: 0.65rem;
}
.form-control.cb-header.active {
  padding: 0.6rem 0.75rem;
}
.cb-header-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cb-list {
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  border: 1px solid #ccc;
  border-top: 0;
  width: 100%;
  padding: 0;
  background-color: #fff;
}
.cb-list,
.cb-header-title {
  font-size: 1rem;
}
.cb-list li {
  height: 32px;
  margin: 0;
}
.cb-list li:nth-child(odd) {
  background-color: #fff;
}
.cb-list li:nth-child(even) {
  background-color: #e2e8f0;
}
.cb-list button {
  width: 100%;
  text-align: left;
  height: 32px;
}
.menu-badge {
  height: 12px;
  width: 20px;
  font-size: 5px;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
