.moonsheet-bg {
  background-image: url(../../assets/images/moonsheet-3.png);
  background-size: contain;
}
.moonsheet-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
}
.moonsheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.moonsheet-title {
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.moonsheet table {
  width: 85%;
  border-collapse: separate;
  border-spacing: 1rem;
  margin: 0 auto;
}
.moonsheet table tr {
  overflow: hidden;
  font-weight: 700;
}
.moonsheet table tr th {
  background-color: #64a5f7;
  color: #fff;
  width: 25%;
  box-shadow: 0px 0px 0px 2px #fff;
}
.moonsheet table tr td {
  color: #000;
  height: 48px;
  text-align: center;
}
.moonsheet table tr td,
.moonsheet table tr th {
  border-collapse: separate;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 0.5rem;
}

.moonsheet table tr:nth-child(odd) {
  background-color: #edf7ff;
}
.moonsheet table tr:nth-child(even) {
  background-color: #d9eafc;
}
.text-yellow {
  color: yellow;
}
