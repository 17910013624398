.login-bg-wrapper {
  background-image: url(/static/media/background3.8eb3e1fd.jpg);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
.login-container {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  padding-top: 10rem;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 13;
}
.activate-container {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 13;
}
.close {
  z-index: 15;
  font-size: 30px;
  top: -30px;
  right: -22px;
  cursor: pointer;
}
.p-40 {
  padding: 2rem;
}
