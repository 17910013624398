.post-content h2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}
.post-content p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;
}

ul {
  font-size: 16px;
  line-height: 20px;
  list-style: disc;
  padding: 0 15px;
}
ul li {
  margin-bottom: 10px;
}
