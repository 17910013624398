.custom-scrollbar {
  overflow: auto;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4); /*Gives styles to the scrollbar*/
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #555555; /*Gives background color to the scrollbar*/
  outline: none;
}
