.additional-logo {
  display: flex;
  align-items: center;
}
.additional-logo .img {
  margin-right: 0.5rem;
  max-height: 100%;
}
.additional-logo.big {
  height: auto;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
.additional-logo.big .img {
  width: 3rem;
}
.additional-logo.large {
  height: auto;
}
.additional-logo.large .img {
  width: 5rem;
  max-height: 5rem;
}
