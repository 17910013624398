.black .tab-header {
  border: none;
  margin: 0;
  margin-bottom: 20px;
}

.black .tab-header button {
  border-radius: 5px;
  border: 1px solid #3f3f3f;
  margin-right: 10px;
  color: #efefef;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-image: linear-gradient(
    to right,
    #666a6b 0%,
    #3f3f3f 51%,
    #d4d4d4 100%
  );
}
.black .tab-header button:hover {
  background-position: right center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.black .tab-header button.active {
  color: #fff;
  border: 1px solid #3a7bd5;
  background-image: linear-gradient(
    to right,
    #00d2ff 0%,
    #2e74d6 51%,
    #3a7bd5 100%
  );
}
.black .tab-header button.active:hover {
  background-position: right center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.black .tab-content {
  box-shadow: 0 1px 8px 0 #706f6f33, 0 0px 10px 0 rgb(112 111 111 / 20%);
}
.black .tab-content th button.sort {
  opacity: 0;
}
.black .tab-content th:hover button.sort,
.black .tab-content th.active button.sort {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

button:focus {
  outline: none;
}
.text-primary {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}
.text-gradient {
  background: -webkit-linear-gradient(#00d2ff, #3a7bd5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.airdrop-social-links a {
  opacity: 0.5;
}
.airdrop-social-links a:hover {
  opacity: 0.75;
}
